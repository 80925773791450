<template>
  <div id="login" class="container p-4">
    <div class="w-100 pb-3 text-left">
      <img src="../assets/image/securemed.png" class="col-12 col-sm-8 col-md-4 col-lg-3">
    </div>
    <form ref="form1" @submit.prevent>
      <div class="form-group row">
        <label for="loginAddress" class="text-left text-sm-center col-sm-3 col-form-label p-sm-1">メールアドレス</label>
        <div class="col-sm-6">
          <input id="loginAddress" v-model="email" type="email" class="form-control" placeholder="email"/>
        </div>
      </div>
      <div class="form-group row">
        <label for="loginPassword" class="text-left text-sm-center col-sm-3 col-form-label p-sm-1">パスワード</label>
        <div class="col-sm-6">
          <input id="loginPassword" v-model="password" type="password" class="form-control" placeholder="password"/>
        </div>
      </div>
      <div class="form-group row justify-content-center mb-4">
        <button class="btn btn-outline-secondary" type="button" @click="sendPin">PIN送信</button>
      </div>
      <div class="form-group row">
        <label for="inputPin" class="col-sm-3 col-form-label"></label>
        <div class="col-sm-6">
          <small class="form-text text-muted text-left">登録メールに送られたPINコードを送信してください。</small>
          <div class="input-group">
            <input v-model="pin" type="text" class="form-control" id="inputPin" placeholder="PIN" required />
            <div class="input-group-append">
              <button type="button" class="btn btn-primary" @click="login">送信</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import vernamCipher from 'vernam_cipher_on_node/vernam_cipher'
import { db } from '@/db'
import { apiError } from '@/api/common.js'
import { sendPinNormal, login, intermediateDecrypt } from '@/api/login.js'
import { aesDecryption, createHashSha256 } from '@/utils/cipher.js'
import { createRandomMnemonic, getAddressByMnemonicAndIndex } from '@/utils/make-request-data.js'
import { CONST } from '@/constants.js'

export default {
  data() {
    return {
      email: "",
      password: "",
      pin: "",
    }
  },
  methods: {
    saveSecretKey: async function(member_code, mnemonic, request_secret_key) {
      const { cipher, cipherKey } = vernamCipher.encryption(mnemonic, request_secret_key)
      const result = await db.member_private_keys.where({member_code: member_code}).toArray()
      if (!result.length) {
        await db.member_private_keys.put({
          'member_code': member_code,
          'cipher_private_key': cipher,
          'decryption_key': cipherKey,
        })
      } else {
        await db.member_private_keys.put({
          'id': result[0].id,
          'member_code': member_code,
          'cipher_private_key': cipher,
          'decryption_key': cipherKey,
        })
      }
    },
    // PIN送信
    sendPin: async function () {
      if (this.isProcessing()) return
      this.startProcessing()
      try {
        let errorText = []
        if (this.email == "") errorText.push('メールアドレスを入力してください。')
        if (this.password == "") errorText.push('パスワードを入力してください。')
        if (errorText.length) {
          alert(errorText.join("\n"))
          this.endProcessing()
          return
        }
        const hashMail = createHashSha256(this.email)
        const hashPass = createHashSha256(this.password)

        // 多重暗号通信
        const resDecryptSendPin = await sendPinNormal({
          email: hashMail,
          password: hashPass,
        }).catch(apiError)

        if (!resDecryptSendPin || !resDecryptSendPin.value) {
          alert('エラーが発生しました。ネットワーク接続を確認してください。')
        } else {
          alert(resDecryptSendPin.value)
        }
        this.endProcessing()
      } catch (err) {
        console.log(err)
        this.endProcessing()
      }
    },

    // ログイン
    login: async function () {
      if (this.isProcessing()) return
      this.startProcessing()
      try {
        let errorText = []
        if (this.email == "") errorText.push('メールアドレスを入力してください。')
        if (this.password == "") errorText.push('パスワードを入力してください。')
        if (this.pin == "") errorText.push('PINコードを入力してください。')
        else if (!this.pin.match(/^[0-9]{6}$/)) errorText.push('PINコードの形式が不正です。6桁の半角数字で入力してください。')
        if (errorText.length) {
          alert(errorText.join("\n"))
          this.endProcessing()
          return
        }

        const hashMail = createHashSha256(this.email)
        const hashPass = createHashSha256(this.password)

        // ウォレット作成
        const mnemonic = createRandomMnemonic()

        // 多重暗号通信ログイン処理
        const resDecryptLogin = await login({
          'email': hashMail,
          'password': hashPass,
          'pin': this.pin,
          'requestSecretKey': mnemonic,
        }).catch(apiError)

        const resDecryptLoginValue = resDecryptLogin.value

        // 中間暗号処理
        const multipleCipheredResDecryptLoginInfo = vernamCipher.intermediateEncrypt(resDecryptLoginValue.cipher)

        // 中間復号化API
        const resMultipleCipheredInfo = await intermediateDecrypt({
          'uuid': resDecryptLoginValue.randomNum,
          'cipher': multipleCipheredResDecryptLoginInfo.cipher
        }).catch(apiError);

        const resEncryptLoginInfo = vernamCipher.decryption(resMultipleCipheredInfo.value.cipher, multipleCipheredResDecryptLoginInfo.cipherKey)
        const resEncryptLoginInfoObj = JSON.parse(resEncryptLoginInfo)

        const memberCode = resEncryptLoginInfoObj.memberCode

        // アカウントアドレス作成
        const address = getAddressByMnemonicAndIndex(mnemonic, resEncryptLoginInfoObj.index)

        // AES復号化
        const personalMnemonic = aesDecryption(resEncryptLoginInfoObj.cipher, address)

        // パーソナル秘密鍵をローカルストレージに保存
        await this.saveSecretKey(memberCode, personalMnemonic.replace(/"/g, ""), mnemonic)
        // sessionStorage にデータを保存する
        sessionStorage.setItem('request_secret_key', mnemonic)
        const now = new Date()
        sessionStorage.setItem('expire', new Date(now.setMinutes(now.getMinutes() + 30)))
        sessionStorage.setItem('login_member_code', memberCode)

        switch(resEncryptLoginInfoObj.activateFlg) {
          // 本番アンケートに遷移
          case CONST.MEMBER.ACTIVATE_FLG.PRODUCTION_AGREE:
            this.$router.push({name: 'Explain'})
            break
          // 終了済み画面に遷移
          case CONST.MEMBER.ACTIVATE_FLG.PRODUCTION_COMPLETED:
            this.$router.push({name: 'ProductionComplete'})
            break
          default:
            // 調査一覧画面に遷移
            this.$router.push({name: 'RegisterResearch'})
        }
        // alert('Login Success!')
      } catch (err) {
        console.log(err)
        this.endProcessing()
      }
    },
    startProcessing() {
      this.processing = true
    },
    endProcessing() {
      this.processing = false
    },
    isProcessing() {
      return this.processing
    },
  },
}
</script>
